<template>
    <div v-if="active" class="content-container checkout-box radius-anchor shadow-anchor">
        <div class="">
            <label for="timeslot_id" class="block mb-3" :class="{'text-red-500': error }">
                <h2 class="text-2xl font-bold leading-6">{{ $t('label.select') }} Timeslot *</h2>
            </label>
            <div v-if="!loading">
                <div v-if="areaRequired" class="">
                    {{ $t('timeslot.selection.arearequired')}}
                </div>
                <div v-else>
                    <div v-if="timeslots.length == 0" class="">
                        {{ $t('timeslot.selection.unavailable')}}
                    </div>
                    <div v-else>
                    <!-- view: inputfield -->
                        <!-- <div v-if="timeslot_view == 'inputfield'" class="sm:grid sm:grid-cols-2 gap-4 mt-5">
                            <div class="sm:col-span-1">
                                <label for="time" class="block text-xs" :class="{'text-red-500': error}">{{ $t('fields.time') }} *</label>
                                <div class="mt-1 relative" v-if="timeslots.length > 0">
                                    <select name="time" v-model="timeslot" as="select" class="h-10 py-1 px-2 form-select w-full shadow-sm border rounded-sm" :class="{'error': errors.time}">
                                        <option></option>
                                        <option :value="timeslot.slot" v-for="(timeslot, index) in timeslots" :key="index" :disabled="!timeslot.available">
                                            {{ timeslot.slot }} <span v-if="!timeslot.available">({{ $t('order.timeslotunavailable') }})</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div> -->

                        <!-- view: calendar -->
                        <div v-if="timeslot_view == 'calendar'" class="mt-5 mb-3">
                            <div class="mt-1 grid grid-cols-2 gap-2">
                                <div v-for="(timeslot, index) in timeslots" :key="index" @click="selectTimeslot(timeslot)" class="btn btn-small checkout-option" :class="{'line-through text-muted': !timeslot.available, 'active': timeslot.slot == this.timeslot}">
                                    {{ timeslot.slot }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import * as yup from 'yup';

// libs
import * as _api from '../lib/api';
import * as _state from '../lib/state';

export default {
    name: 'FormTimeslotSelection',
    data () {
        return {
            loading: true,
            timeslot: null,
            timeslot_view: '',
            timeslots: [],
            error: false,
            area_id: null,
        }
    },

    async mounted() {

        await this.load();

        this.loading = false;
    },

    methods: {


        async load(area_id = null) {
            this.area_id = area_id;

            if (this.areaRequired) {
                this.timeslots = [];
                return;
            }
            await this.getTimeslots();
        },
        async validate() {
            this.error = false;

            let schema = yup.object().shape({
                timeslot: yup.string().required(),
            });

            if (await schema.isValid({timeslot: this.timeslot}) == false) {
                this.error = true;
                return false;
            }

            return true;
        },


        async getTimeslots() {
            const response = await _api.post('timeslots', {
                date: moment().format('YYYY-MM-DD'),
                // function: 'quickorder',
                function: this.transaction.method,
                area_id: this.area_id,
            });

            if (response && response.attributes) {
                this.timeslot_view = response.attributes.view ? response.attributes.view : 'inputfield'
                this.timeslots = response.attributes.timeslots;
            } else {
                this.timeslots = [];
            }
        },

        // selectTimeslot(timeslot) {
        //     this.timeslot_id = timeslot;
        //     console.log(this.timeslot)
        //     this.validate();
        //     this.$emit('changed', { timeslot: this.timeslot });
        // },

        selectTimeslot(timeslot) {
            if (!timeslot.available) {
                // _notification.set(this.$t('notification.datetime-selection.timeslotunavailable'), 'error')
                return;
            }
            this.timeslot = timeslot.slot;
            this.validate();
            this.$emit('changed', { date: moment().format('YYYY-MM-DD'), time: this.timeslot });
        },

        // selectTimeslot(timeslot) {
        //     if (!timeslot.available) {
        //         _notification.set(this.$t('notification.datetime-selection.timeslotunavailable'), 'error')
        //         return;
        //     }
        //     this.timeslot = timeslot.slot;
        // },

        // emit() {
        //     this.validate();
        //     this.$emit('changed', this.form);
        // },
    },

    computed: {
        active() {
            return this.transaction.method === 'quickorder' && this.location.quickorder.timeslotselection == true;
        },

        areaRequired() {
            return this.location.quickorder.areaselection == true && !this.area_id;
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        location() {
            return _state.get('location/getLocation');
        },
    }
}
</script>
