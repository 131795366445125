<template>
    <div v-if="active" class="content-container checkout-box radius-anchor shadow-anchor">
        <div>
            <label for="area_id" class="block mb-3" :class="{'text-red-500': error }">
                <h2 class="text-2xl font-bold leading-6">{{ $t('label.select') }} Area *</h2>
            </label>
            <div v-if="!loading">
                <div v-if="areas.length == 0" class="">
                    {{ $t('area.selection.unavailable')}}
                </div>
                <div v-else class="grid grid-cols-2 gap-2">
                    <template v-for="(area, index) in areas" :key="index">
                        <button @click="selectArea(area.id);" class="btn w-full checkout-option" :class="{'active': area.id == area_id}">
                            <p>{{ area.attributes.name }}</p>
                            <p class="text-muted">{{ area.attributes.description }}</p>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as yup from 'yup';

// libs
import * as _api from '../lib/api';
import * as _state from '../lib/state';

export default {
    name: 'FormAreaSelection',
    data () {
        return {
            loading: true,
            area_id: null,
            areas: [],
            error: false,
        }
    },

    async mounted() {
        // this.areas = await _api.get('reservations/areas');

        await this.getAreas();

        this.loading = false;
    },

    methods: {

        async validate() {
            this.error = false;

            let schema = yup.object().shape({
                area_id: yup.string().required(),
            });

            if (await schema.isValid({area_id: this.area_id}) == false) {
                this.error = true;
                return false;
            }

            return true;
        },


        async getAreas() {
            this.areas = await _api.get('/location/areas-selection');

            if (this.areas.length === 1) {
                this.selectArea(this.areas[0].id);
            }
        },

        selectArea(area_id) {
            this.area_id = area_id;
            this.validate();
            this.$emit('changed', { area_id: this.area_id });
        },
    },

    computed: {
        active() {
            return this.transaction.method === 'quickorder' && this.location.quickorder.areaselection == true;
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        location() {
            return _state.get('location/getLocation');
        },
    }
}
</script>
